/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { blogMDXComponents } from '../utils/helpers/blog-mdx';

import BaseTemplate from '../components/templates/BaseLayout';
import PrimaryHeader from '../components/atoms/typography/PrimaryHeader';
import SEO from '../components/atoms/utility/seo';
import NextPrevLinks from '../components/atoms/blog/NextPrevLinks';
import Spacer from '../components/atoms/utility/Spacer';

function BlogPostTemplate({ data, pageContext }) {
    const post = data.mdx;
    const { previous, next } = pageContext;
    const localImages = post.frontmatter.embeddedImagesLocal;
    const remoteImages = post.embeddedImagesRemote;

    return (
        <BaseTemplate>
            <SEO
                title={post.frontmatter.title}
                description={post.frontmatter.description}
            />
            <div className="max-w-4xl w-full md:px-6 lg:px-0 mx-auto my-10 lg:my-20">
                <PrimaryHeader headerText={post.frontmatter.title} />
                <div className="text-white flex-row justify-between my-6">
                    <span className="text-xs font-normal">
                        {post.frontmatter.date}
                    </span>
                    <span className="text-xs font-normal mx-3">/</span>
                    <span className="text-xs font-normal">
                        {`${post.timeToRead} ${
                            post.timeToRead > 1 ? 'minutes' : 'minute'
                        } reading`}
                    </span>
                </div>

                <MDXProvider components={blogMDXComponents}>
                    <MDXRenderer
                        remoteImages={remoteImages}
                        localImages={localImages}
                    >
                        {post.body}
                    </MDXRenderer>
                </MDXProvider>
                <NextPrevLinks next={next} previous={previous} />
                <Spacer />
            </div>
        </BaseTemplate>
    );
}

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostById($id: String!) {
        mdx(id: { eq: $id }) {
            id
            excerpt(pruneLength: 160)
            body
            timeToRead
            headerImage {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            embeddedImagesRemote {
                childImageSharp {
                    gatsbyImageData
                }
            }
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
                embeddedImagesLocal {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
                slug
            }
        }
    }
`;
