import React from 'react';
import { Link } from 'gatsby';

export default function NextPrevLinks({ next, previous }) {
    const buttonStyles = `border border-grey hover:shadow-lg bg-transparent hover:bg-grey rounded-lg px-4 py-2 w-full transition`;
    const nextPrevStyles = `text-sm text-grey group-hover:text-highlight transition ease-linear`;
    const titleStyles = `text-grey group-hover:text-white transition ease-linear line-clamp-1`;

    return (
        <div className="text-white flex flex-col md:flex-row w-full justify-between items-center gap-4 mt-10">
            {previous ? (
                <Link to={previous.frontmatter.slug} className="w-full group">
                    <button className={buttonStyles}>
                        <p className={nextPrevStyles + ' text-left'}>
                            Previous page
                        </p>
                        <p className={titleStyles + ' text-left'}>
                            {previous.frontmatter.title}
                        </p>
                    </button>
                </Link>
            ) : (
                <div className="w-full"></div>
            )}
            {next ? (
                <Link to={next.frontmatter.slug} className="w-full group">
                    <button className={buttonStyles}>
                        <p className={nextPrevStyles + ' text-right'}>
                            Next page
                        </p>
                        <p className={titleStyles + ' text-right'}>
                            {next.frontmatter.title}
                        </p>
                    </button>
                </Link>
            ) : (
                <div className="w-full"></div>
            )}
        </div>
    );
}
