import React from 'react';

export default function Spacer({ sizeX = 1, sizeY = 1 }) {
    let xPadding = '',
        yPadding = '';

    if (sizeX === 1) {
        yPadding = 'pt-10 sm:pt-20 md:pt-40 lg:pt-60';
    }

    return <div className={xPadding + ' ' + yPadding}></div>;
}
