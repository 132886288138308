import React from 'react';

const blogHeader1 = (props) => (
    <h1
        className="mt-6 mb-4 font-extrabold text-3xl md:text-4xl lg:text-5xl"
        {...props}
    />
);

const blogHeader2 = (props) => (
    <h2
        className="mt-6 lg:mt-10 mb-4 text-2xl md:text-3xl lg:text-4xl"
        {...props}
    />
);

const blogHeader3 = (props) => (
    <h3 className="mt-5 mb-3 text-xl md:text-2xl lg:text-3xl" {...props} />
);

const blogHeader4 = (props) => (
    <h4 className="mt-4 mb-2 text-lg md:text-xl lg:text-2xl" {...props} />
);

const blogHeader5 = (props) => (
    <h5 className="mt-3 mb-2 md:text-lg lg:text-xl" {...props} />
);

const blogHeader6 = (props) => (
    <h6 className="mt-2 mb-2 text-sm md:text-base lg:text-lg" {...props} />
);

const blogParagraph = (props) => (
    <p className="my-4 tracking-normal" {...props} />
);

const blogAnchor = (props) => (
    <a
        className="my-2 text-highlight tracking-wide hover:underline"
        {...props}
    />
);

const blogBlockquote = (props) => (
    <blockquote
        className="pl-5 pr-2 text-lg italic border-l-4 border-gray-500"
        {...props}
    />
);

const blogPre = (props) => (
    <pre
        className="shadow-inner bg-gray-700 text-sm md:text-base rounded-md p-8 my-4 overflow-y-scroll"
        {...props}
    />
);

const blogOL = (props) => <ol className="my-2 list-decimal px-8" {...props} />;

const blogUL = (props) => <ul className="my-2 list-disc px-8" {...props} />;

const blogCode = (props) => <code className="text-green-400" {...props} />;

export const blogMDXComponents = {
    h1: blogHeader1,
    h2: blogHeader2,
    h3: blogHeader3,
    h4: blogHeader4,
    h5: blogHeader5,
    h6: blogHeader6,
    p: blogParagraph,
    a: blogAnchor,
    blockquote: blogBlockquote,
    pre: blogPre,
    ol: blogOL,
    ul: blogUL,
    code: blogCode,
};
